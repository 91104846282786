<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold">Test</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <img src="@/assets/icons/search.svg" alt height="17.7px" />
      </v-btn>
    </v-app-bar>

    <div class="player">
      <h3>Using Html5 to play m3u8 media file</h3>
      <video-player
        ref="videoPlayer"
        class="vjs-custom-skin"
        :options="playerOptions"
      >
      </video-player>
    </div>
  </div>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import VideoPlayer from 'vue-videojs7/src/components/VideoPlayer.vue'

export default {
  name: 'InboxPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon,
    VideoPlayer,
  },

  data() {
    return {
      playerOptions: {
        autoplay: false,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
      },
    }
  },

  computed: {
    player() {
      return this.$refs.videoPlayer.player
    },
  },

  methods: {
    playVideo: function (source) {
      const video = {
        withCredentials: false,
        type: 'application/x-mpegurl',
        src: source,
      }
      this.player.reset()
      this.player.src(video)
    },
  },
  mounted() {
    const src =
      'https://d20iqr1d7t9tgb.cloudfront.net/13fe3edd1033735267e6541b0d0a01be.m3u8'
    this.playVideo(src)
  },
}
</script>

<style scoped>
.player {
  position: absolute !important;
  width: 100%;
  height: 60%;
}
.vjs-custom-skin {
  height: 60% !important;
}

.vjs-custom-skin /deep/ .video-js {
  height: 60%;
}
</style>
